import { useMemo, useEffect, useState } from "react";

const useObserve = (hiddenElements) => {
  const [isIntersecting, setIsIntersecting] = useState(false),
    [target, setTarget] = useState(null);

  const intersectionCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("show");
        setIsIntersecting(true);
        setTarget(entry.target);
      } /*else {
        entry.target.classList.remove("show");
      }
      entry.target.classList.toggle("show", entry.isIntersecting);
      if (entry.isIntersecting) {
        setIsIntersecting(true);
        setTarget(entry.target);
      }*/
      //observer.unobserve(entry.target);  remove observer from entry
    });
  };

  const intersectionOptions = useMemo(() => {
    return {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      intersectionCallback,
      intersectionOptions
    );
    isIntersecting && observer.unobserve(target);
    hiddenElements.forEach((el) => observer.observe(el.current));
    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el.current));
    };
  }, [hiddenElements, intersectionOptions, isIntersecting, target]);
};

export default useObserve;
